<template>
<div>
    <div class="grid-container full">
        <div class="grid-x">
            <div class="small-12 cell video-container" v-bind:style="image">
                <div class="responsive-embed">

                    <video class="show-for-medium" autoplay muted>
                        <source v-if="isEvening" v-bind:src="require(`../assets/Tofrontdoor1900.mp4`)"  type="video/mp4" />
                        <source v-else v-bind:src="require(`../assets/Tofrontdoor1500.mp4`)"  type="video/mp4" />
                    </video>

                    <span class="show-for-medium">
                        <h1><em>Dos Hermanas En La Mesa</em></h1>
                        <h3 class="subheader"><em>| Two Sisters On The Mesa</em></h3>
                    </span>

                </div>

                <div class="grid-x show-for-small-only">
                    
                    <span class="small-12 cell" style="padding-left: 1em; padding-bottom: 3em; color: #fff;">
                        <h1><em>Dos Hermanas En La Mesa</em></h1>
                        <h3 class="subheader"><em>| Two Sisters On The Mesa</em></h3>
                    </span>
                </div>

                <div class="grid-x show-for-xlarge">
                    <div class="small-12 cell text-center chevron">
                        <span class="chevron-itms">
                            <small>Scroll</small><br>
                            <font-awesome-icon class="chevron-down" icon="chevron-down"/>
                        </span>
                    </div>
                </div>        
                
            </div>
        </div>
    </div>
    <div class="grid-container infos">
        <div class="grid-x grid-margin-x grid-padding-y">

            <div class="small-12 cell">
                <h2 class="subheader"><em>Videos</em></h2>
            </div>

            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/Tofrontdoor1500.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/Tofrontdoor1900.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/1stto2nd1200hrs.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/1stto2nd1930hrs1.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/EntrytoKitchen1430.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/Enscape_2022-04-06-00-55-53_1.mp4`)" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="small-6 medium-4 cell">
                <div class="responsive-embed widescreen">
                    <video controls>
                        <source v-bind:src="require(`../assets/DJI_0142.mp4`)"  type="video/mp4" />
                    </video>
                </div>
            </div>

            <div class="small-12 cell">
                <h2 class="subheader"><em>Downloads</em></h2>
            </div>
            
            <div class="small-12 cell">
                <p>Site and Floor Plan</p>
                <a href="https://roberteggleton.net/doshermanasenlamesa/assets/North Realtor Set 20220328.pdf" class="button hollow large expanded"><font-awesome-icon class="file-pdf" icon="file-pdf"/> Download</a>
            </div>

            <div class="small-12 cell">
                <p>Architects Statement</p>
                <a href="https://roberteggleton.net/doshermanasenlamesa/assets/20220223172747661.pdf" class="button hollow large expanded"><font-awesome-icon class="file-pdf" icon="file-pdf"/> Download</a>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import dayJs from "dayjs" 
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
    name: "Home",
    props: {
        msg: {
            type: String,
            default: "WTF?"
        }
    },
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            image: {
                backgroundImage: "",
                backgroundPosition: "cover center",
                backgroundRepeat: "no-repeat"
            },
        }
    },
    beforeMount() {
        gsap.registerPlugin(ScrollTrigger); 
        var poster_img = "poster_day.jpg"
        if(this.isEvening)
        {
            poster_img = "poster_night.jpg"
        }
        this.image.backgroundImage = "linear-gradient(0deg, rgba(20,20,20, 1) 20%, rgba(20,20,20,0) 50%), url(" + require(`../assets/` + poster_img + ``) + ")"
    },
    mounted() {
        const tl = gsap.timeline()

        tl.to(".chevron-itms",{scrollTrigger: {target: ".chevron-itms", start: "top top", end: "+=30", scrub: 1}, autoAlpha: 0} )
    },
    methods: {
        helloBtn() {
        console.log("Clicked!!");
        }
    },
    computed: {
        isEvening() {
            return dayJs(new Date).hour() > 17;
        }
    }
}
</script>

<style lang="scss" scoped>

    .hollow {
        border-color: #666;
        color: #666;
        &:hover {
            border-color: #fff;
            color: #fff;
        }
    }

    p {
        color: #fff;
    }

    .chevron {
        & small {
            font-size: .35em;
        }
        color: #fff;
        position: absolute;
        bottom: 80px;
        z-index: 10;
        font-size: 3em;  
    }

    .responsive-embed {
        margin-top: -20px;
        color: #fff;
        //display: flex;
        & span {
            position: absolute;
            z-index: 10;
            //align-self: flex-end;
            margin-left: 2em;
            margin-top: 20em;
        }
    }

    /* Small only */
    @media screen and (max-width: 39.9375em) {
        .video-container {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-top: -20px;
        }
        .responsive-embed {
            & span {
                margin-top: 0;              
            }

        }
    }

    /* Medium only */
    @media screen and (min-width: 40em) {
        .responsive-embed video {
            width: auto;
            height: 100%;
        }
    } 

    /* xxLarge and up */
    @media screen and (min-width: 90em) {
        .responsive-embed video {
            width: 100%;
            height: auto;
        }
        .infos {
            margin-top:-18em;
        }
    }
</style>