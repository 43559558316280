import Vue from "vue";
import VueRouter from 'vue-router';
import App from "./App.vue";
import $ from "jquery";
import Foundation from "foundation-sites";
import store from "./store.js";
//Install fontawesome, popper.js & lodash
//npm i --save @fortawesome/fontawesome-free @fortawesome/fontawesome-svg-core @fortawesome/free-solid-svg-icons @fortawesome/vue-fontawesome popper.js lodash
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimes, faBars, faArrowsAltH, faArrowsAlt, faPrint, faArrowLeft, faArrowRight, faMapMarkerAlt, faChevronDown, faFilePdf} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faBars, faArrowsAltH, faArrowsAlt, faPrint, faArrowLeft, faArrowRight, faMapMarkerAlt, faChevronDown, faFilePdf);


import Home from "./components/Home"

Vue.use(VueRouter);
Vue.config.productionTip = false;

const routes = [
  { path: "/", component: Home },
]

const router = new VueRouter({ routes, mode: 'history' });

//Vue directive control of foundation equalizer
//TODO: Add an Foundation.MediaQuery.current and _reInit()
Vue.directive('f-equalizer', {
  bind: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  inserted: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  update: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  unbind: function (el) {
    $(el).foundation('_destroy');
    el.equalizer = null;
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

