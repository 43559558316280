<template>
  <div id="app">


    <div class="off-canvas position-right" id="offCanvas" data-off-canvas>
      <!-- Close button -->
      <button class="close-button button large clear" aria-label="Close menu" type="button" style="color: #fff;" data-close>
        <font-awesome-icon class="times" icon="times" />
      </button>

      <!-- Menu -->
      <ul class="vertical menu" style="padding-top: 3em;">
        <li><router-link to="/">Home</router-link></li>
      </ul>
    </div>
    <div class="off-canvas-content" data-off-canvas-content>


          <button type="button" class="bar-btn button large hide-for-print hide-for-medium" data-toggle="offCanvas"><font-awesome-icon class="bars" icon="bars" /></button><br>

          <router-view></router-view>


          <div class="footer text-right">
            &copy; 2022
          </div>

    </div>
  </div>
</template>

<script>
import $ from "jquery"
import Foundation from "foundation-sites"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "App",
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    this.offCanvas = new Foundation.OffCanvas($('#offCanvas'));
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/klm2npy.css");
// Setting up Foundation scss based on: https://stackoverflow.com/questions/50844644/configuring-foundation-6-and-vue-cli
@import '_settings.scss';
@import '~foundation-sites/scss/foundation.scss';
@include foundation-everything;
//@include foundation-button;
//@include foundation-slider;

[v-cloak] {
  display: none;
}
body {
  background-color: $primary-color;
}
.vertical.menu li h4 {
  margin-bottom: 0;
}
.vertical.menu a {
  color: $white;
}
.vertical.menu a:hover {
  color: $light-gray;
}
.bar-btn {
  margin-bottom:0;
  position:fixed;
  top:8px;
  right:8px;
  z-index:6;
  border-radius: 30px;
  opacity: .8;
  padding: .42em .5em !important;
}
.primary-border-top-bottom {
  border-top: 3px solid $primary-color;
  border-bottom: 3px solid $primary-color;
}
.primary-border-left-bottom {
  border-left: 3px solid $primary-color;
  border-bottom: 3px solid $primary-color;
}
.primary-border-top {
  border-top: 3px solid $primary-color;
}
.primary-background {
  background-color: $primary-color;
  color: $white;
}
.secondary-background {
  background-color: $secondary-color;
}
hr {
  border: 1px dotted $secondary-color;
  width: 33%;
}
.footer {
  position: relative;
  color:$dark-gray;
  border-top: 1px dotted $secondary-color;
  padding: .5em .8em;
}
/*Remove outline that browsers will render*/
button {
  outline: none;
}

.dropdown.menu a {
  color: $black;
  /*border-bottom: 3px solid $secondary-color;
  margin-right: 10px;*/
}
.menu .is-active > a {
  background: $secondary-color;
  color: $white;
  /*border-bottom: 3px solid $secondary-color;*/
}
.menu .submenu {
  background: $secondary-color;
  color: $white !important;
  border: 3px solid $secondary-color;
}
.menu .submenu a {
  color: $white;
}
</style>
